<template>
  <container>
    <h1 v-if="editType == 'ndc'">Edit Drug</h1>
    <h1 v-else>Edit GCN</h1>
    <drug-form
      v-model="drug"
      @reset="reset"
      @submit="save"
      @approve="approve"
      :errors="errors"
      :editType="editType"
      :errorFields="errorFields"
      :success="success"
    ></drug-form>
  </container>
</template>

<script>
import Container from "@/components/Container.vue";
import DrugForm from "@/components/DrugForm.vue";
import DrugService from "@/services/drugs.js";
import GcnService from "@/services/gcns.js";
import DrugFields from "@/lib/enum/drug_field.js";

export default {
  name: "EditDrug",
  props: {
    editType: { type: String, default: "ndc" }
  },
  data() {
    return {
      drugService:
        this.editType === "ndc" ? new DrugService() : new GcnService(),
      originalDrug: {},
      drug: {},
      schedules: ["I", "II", "III", "IV"].map(s => {
        return { value: s, text: `Schedule ${s}` };
      }),
      errors: [],
      errorFields: [],
      success: ""
    };
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    },

    reset() {
      this.drug = { ...this.originalDrug };
      this.$store.commit("hideLoading");
      this.errors = [];
      this.errorFields = [];
      this.scrollTop();
    },

    async save() {
      this.errors = [];
      this.errorFields = [];
      this.$store.commit("showLoading");
      try {
        this.success = "";
        await this.drugService.save(this.drug);
        this.success = "The drug was saved successfully";
      } catch (err) {
        let response = err.response;
        if (response && (response.status).toString().match(/^[54]{1}[0-9]{2}$/)) {
          if (response.data.error) {
            this.errors.push(response.data.error);
          } else if (response.data.errors) {
            for (const [key, value] of Object.entries(response.data.errors)) {
              this.errorFields.push(key);
              const description = DrugFields[key].description;
              this.errors.push(`${description} ${value}`);
            }
          }
        }
      }
      this.$store.commit("hideLoading");
      this.scrollTop();
    },

    async approve() {
      await this.drugService.approve(this.drug.gcn_seqno);
      this.$router.push({ name: "GcnUnapproved" });
    }
  },
  async beforeMount() {
    this.$store.commit("showLoading");
    this.originalDrug = await this.drugService.get(this.$route.params.id);
    this.reset();
    this.$store.commit("hideLoading");
  },
  components: { Container, DrugForm }
};
</script>

<style></style>
