<template>
  <b-form
    @reset.prevent="$emit('reset')"
    @submit.prevent="$emit('submit')"
    class="mb-5"
    novalidate
  >
    <!-- Success -->
    <b-form-row>
      <b-col md="8" lg="6">
        <b-alert variant="success" :show="success.length > 0">
          {{ success }}
        </b-alert>
      </b-col>
    </b-form-row>

    <!-- Errors -->
    <b-form-row>
      <b-col md="8" lg="6">
        <b-alert variant="danger" :show="errors.length > 0">
          <ul class="mb-0">
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </b-alert>
      </b-col>
    </b-form-row>

    <!-- Row 1 -->
    <b-form-row>
      <b-col v-if="editType == 'ndc'" md="4" lg="3"
        ><b-form-group label="Gcn Seq No" label-for="gcn"
          ><b-form-input
            id="gcn"
            :value="value.gcn"
            size="sm"
            @input="update('gcn', $event)"
            :state="fieldState('gcn')"
          ></b-form-input></b-form-group
      ></b-col>
      <b-col md="4" lg="3">
        <b-form-group label="Strength" label-for="strength">
          <b-form-input
            id="strength"
            :value="value.strength"
            size="sm"
            @input="update('strength', $event)"
            :state="fieldState('strength')"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <!-- Row 2 -->
    <b-form-row>
      <b-col md="4" lg="3"
        ><b-form-group label="Generic Name" label-for="generic_name"
          ><b-form-input
            id="generic_name"
            :value="value.generic_name"
            size="sm"
            @input="update('generic_name', $event)"
            :state="fieldState('generic_name')"
          ></b-form-input></b-form-group
      ></b-col>
      <b-col md="4" lg="3"
        ><b-form-group label="Label Name" label-for="label_name"
          ><b-form-input
            id="label_name"
            :value="value.label_name"
            size="sm"
            @input="update('label_name', $event)"
            :state="fieldState('label_name')"
          ></b-form-input></b-form-group
      ></b-col>
    </b-form-row>

    <!-- Row 3 -->
    <b-form-row>
      <b-col md="4" lg="3">
        <b-form-group label="Drug Schedule" label-for="drug_schedule">
          <b-form-select
            id="drug_schedule"
            :value="value.drug_schedule"
            @input="update('drug_schedule', $event)"
            size="sm"
            :options="schedules"
            :state="fieldState('drug_schedule')"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="4" lg="3"
        ><b-form-group label="Route" label-for="route"
          ><b-form-input
            id="route"
            :value="value.route"
            size="sm"
            @input="update('route', $event)"
            :state="fieldState('route')"
          ></b-form-input></b-form-group
      ></b-col>
    </b-form-row>

    <!-- Row 4 -->
    <b-form-row>
      <b-col md="8" lg="6">
        <b-form-group label="Description" label-for="desc"
          ><b-form-input
            id="desc"
            :value="value.desc"
            size="sm"
            @input="update('desc', $event)"
            :state="fieldState('desc')"
          ></b-form-input
        ></b-form-group>
      </b-col>
    </b-form-row>

    <!-- Row 5 -->
    <b-form-row>
      <b-col md="8" lg="6">
        <b-form-group label="Therapeutic Class" label-for="therapeutic_class"
          ><b-form-input
            id="therapeutic_class"
            :value="value.therapeutic_class"
            size="sm"
            @input="update('therapeutic_class', $event)"
            :state="fieldState('therapeutic_class')"
          ></b-form-input
        ></b-form-group>
      </b-col>
    </b-form-row>

    <!-- Row 6 -->
    <b-form-row>
      <b-col md="8" lg="6">
        <b-form-group label="Source" label-for="source"
          ><b-form-input
            id="source"
            :value="value.source"
            size="sm"
            @input="update('source', $event)"
            :state="fieldState('source')"
          ></b-form-input
        ></b-form-group>
      </b-col>
    </b-form-row>

    <!-- Row 7 -->
    <b-form-row>
      <b-col md="4" lg="3">
        <b-form-group label="BJA Type" label-for="bja_type">
          <b-form-select
            id="bja_type"
            :value="value.bja_type"
            size="sm"
            @input="update('bja_type', $event)"
            :options="bjaTypes"
            :state="fieldState('bja_type')"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="4" lg="3">
        <b-form-group label="Duration Type" label-for="duration_type">
          <b-form-select
            id="duration_type"
            :value="value.duration_type"
            size="sm"
            @input="update('duration_type', $event)"
            :options="durationTypes"
            :state="fieldState('duration_type')"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-form-row>

    <!-- Row 8 -->
    <b-form-row>
      <b-col md="8" lg="6">
        <b-form-group label="AHFS Description" label-for="ahfsi_description">
          <b-form-textarea
            id="ahfsi_description"
            size="sm"
            :value="value.ahfsi_description"
            @input="update('ahfsi_description', $event)"
            :state="fieldState('ahfsi_description')"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-form-row>

    <!-- Row 9 -->
    <b-form-row>
      <b-col md="8" lg="6">
        <b-form-group label="Citation" label-for="citation">
          <b-form-textarea
            id="citation"
            size="sm"
            :value="value.citation"
            @input="update('citation', $event)"
            :state="fieldState('citation')"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-form-row>

    <!-- Row 10 -->
    <b-form-row>
      <b-col md="8" lg="6" class="mb-3">
        <b-card bg-variant="light">
          <b-form-row v-for="ingr in [1, 2, 3]" :key="ingr">
            <b-col md="3">
              <b-form-group
                :label="`Narx Unit ${ingr}`"
                :label-for="`narx_unit_eq_${ingr}`"
                ><b-form-input
                  :id="`narx_unit_eq_${ingr}`"
                  size="sm"
                  type="number"
                  :value="parseFloat(value[`narx_unit_eq_${ingr}`])"
                  @input="
                    update(`narx_unit_eq_${ingr}`, parseFloatInput($event))
                  "
                  :state="fieldState(`narx_unit_eq_${ingr}`)"
                ></b-form-input
              ></b-form-group>
            </b-col>
            <b-col md="3"
              ><b-form-group
                :label="`Narx Type ${ingr}`"
                :label-for="`narx_type_${ingr}`"
              >
                <b-form-select
                  :id="`narx_type_${ingr}`"
                  :value="value[`narx_type_${ingr}`]"
                  size="sm"
                  @input="update(`narx_type_${ingr}`, $event)"
                  :options="narxTypes"
                  :state="fieldState(`narx_type_${ingr}`)"
                ></b-form-select
              ></b-form-group>
            </b-col>
            <b-col md="3"
              ><b-form-group
                :label="`Active Ingredient ${ingr}`"
                :label-for="`narx_active_ingredient_${ingr}`"
                ><b-form-input
                  :id="`narx_active_ingredient_${ingr}`"
                  :value="value[`narx_active_ingredient_${ingr}`]"
                  size="sm"
                  @input="update(`narx_active_ingredient_${ingr}`, $event)"
                  :state="fieldState(`narx_active_ingredient_${ingr}`)"
                ></b-form-input
              ></b-form-group>
            </b-col>
            <b-col md="3"
              ><b-form-group
                :label="`Narx Dose ${ingr}`"
                :label-for="`narx_dose_${ingr}`"
                ><b-form-input
                  :id="`narx_dose_${ingr}`"
                  :value="parseFloat(value[`narx_dose_${ingr}`])"
                  type="number"
                  size="sm"
                  @input="update(`narx_dose_${ingr}`, parseFloatInput($event))"
                  :state="fieldState(`narx_dose_${ingr}`)"
                ></b-form-input
              ></b-form-group>
            </b-col>
          </b-form-row>
        </b-card>
      </b-col>
    </b-form-row>

    <!-- Row 11 -->
    <b-form-row>
      <b-col>
        <b-button variant="appriss-light" type="submit">Save</b-button>
        <b-button variant="warning" type="reset" class="ml-1">Reset</b-button>
        <b-button
          v-if="editType == 'gcn'"
          variant="danger"
          class="ml-1"
          @click.prevent="approve"
          >Approve</b-button
        >
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import narxTypes from "@/lib/menu/narx_type.js";
import bjaTypes from "@/lib/menu/bja_type.js";
import durationTypes from "@/lib/menu/duration_type.js";
import schedules from "@/lib/menu/schedules.js";

export default {
  name: "DrugForm",
  data() {
    return {
      originalDrug: {},
      internalDrug: {},
      schedules,
      narxTypes,
      bjaTypes,
      durationTypes
    };
  },
  methods: {
    update(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    },
    approve() {
      this.$emit("approve");
    },
    fieldState(key) {
      if (this.errors.length === 0) {
        return null;
      }

      return !this.errorFields.includes(key);
    },
    parseFloatInput(value) {
      let parsedValue = parseFloat(value);

      if (parsedValue === 0) return parsedValue;
      return parsedValue || null;
    }
  },
  props: ["value", "errors", "errorFields", "editType", "success"]
};
</script>

<style></style>
